var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "source-options" },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        !_vm.visible
          ? _c(
              "div",
              { key: "btns" },
              [
                _c("div", { staticClass: "source-options__row" }, [
                  !_vm.accessRules.created
                    ? _c(
                        "button",
                        {
                          staticClass: "source-options__link",
                          attrs: { disabled: _vm.loading },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$emit("create-users-spreadsheet")
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "source-options__link-icon icon-edit-google-sheet",
                          }),
                          _vm._v("\n          CREATE USERS SHEET\n        "),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "source-options__link",
                          attrs: {
                            href: _vm.accessRules.spreadsheetUrl,
                            target: "_blank",
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "source-options__link-icon icon-edit-google-sheet",
                          }),
                          _vm._v("\n          EDIT USERS SHEET\n        "),
                        ]
                      ),
                  _vm.accessRules.created
                    ? _c(
                        "button",
                        {
                          staticClass: "source-options__button",
                          attrs: {
                            disabled: _vm.loading,
                            title: "Refresh Users Sheet data",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$emit("refresh-users-spreadsheet")
                            },
                          },
                        },
                        [
                          !_vm.refreshingSheetData
                            ? _c("i", { staticClass: "icon-refresh-arrow" })
                            : _c("div", { staticClass: "icon-loader" }),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("GoogleFilePicker", {
                  staticClass: "source-options__change-btn",
                  attrs: {
                    disabled: _vm.loading,
                    buttonText: !_vm.accessRules.created
                      ? "Add existing sheet"
                      : "Change source sheet",
                  },
                  on: {
                    "on-link-chosen": (val) => {
                      _vm.$emit("update-spread-sheet-url", val)
                      _vm.$emit("connect-users-spreadsheet", val)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "side-form__item", staticStyle: { "margin-top": "30px" } },
      [
        _c(
          "a",
          {
            staticClass: "sorting-constructor__link",
            attrs: {
              target: "_blank",
              href: "https://help.spreadsimple.com/en/article/how-to-set-up-website-access-rules-1vre7ri/",
            },
          },
          [_vm._v("\n      Help: How to set up website access rules?\n    ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }