<template>
    <div>
    <UiBtn @click="openPicker" :loading="isLoading" :disabled="isLoading" class="ui-btn--full-width">
        <span>{{ buttonText }}</span>
    </UiBtn>
    <br>
    </div>
</template>
<script>
import { initGoogleSignInScript, loadGapiScript, getGoogleDrivePermissions } from '@/modules/google-utils'
import { client as apiClient } from '@/services/api'
import { mapState } from 'vuex'
import UiBtn from '@/components/ui-btn/UiBtn.vue'
export default {
  name: 'GoogleFilePicker',
  components: {
    UiBtn
  },
  data: () => {
    return {
      accessToken: null,
      picker: null,
      isLoading: false,
      googleApiKey: process.env.VUE_APP_GOOGLE_PICKER_API_KEY,
      googleAppId: process.env.VUE_APP_GOOGLE_APP_ID
    }
  },
  props: {
    buttonText: {
      type: String,
      default: 'Get link to the SpreadSheet'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ])
  },
  watch: {
    disabled (val) {
      this.isLoading = val
    }
  },
  methods: {
    async openPicker() {
      this.isLoading = true
      if (!this.accessToken) {
        await this.initScripts()
      }
      if (this.picker) {
        this.picker.setVisible(true)
        this.isLoading = false
      }
    },
    pickerCallback (data) {
      if (data.action === 'picked') {
        this.$emit('on-link-chosen', data.docs[0].url)
        this.isLoading = false
      }
      if (data.action === 'cancel') {
        this.isLoading = false
      }
    },
    createPicker () {
      if (!this.accessToken) {
        return
      }
      const docsView = new window.google.picker.DocsView()
        .setMode(window.google.picker.DocsViewMode.LIST)
        .setMimeTypes('application/vnd.google-apps.spreadsheet')

      this.picker = new window.google.picker.PickerBuilder()
        .addView(docsView)
        .setOAuthToken(this.accessToken)
        .setDeveloperKey(this.googleApiKey)
        .setCallback(this.pickerCallback)
        .setAppId(this.googleAppId)
        .build()

      this.picker.setVisible(true)
    },
    deobfuscateToken (obfuscatedToken) {
      const decoded = atob(obfuscatedToken)
      const [part1, _noise, part2] = decoded.split(':')
      return part1 + part2
    },

    async initScripts () {
      try {
        const { data } = await apiClient.get(`/google/access-token`)
        await initGoogleSignInScript()
        await loadGapiScript()
        this.accessToken = this.deobfuscateToken(data.access_token)
        window.gapi.load('picker', this.createPicker)
      } catch (e) {
        console.warn(e)
        const permissions = await getGoogleDrivePermissions(this.userData.email, 'https://www.googleapis.com/auth/drive.file')
        if (!permissions) {
          this.isLoading = false
          return
        }
        this.initScripts()
      }
    }
  }
}

</script>
