var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-create" },
        [
          _c("img", {
            staticClass: "page-create__decor-img is-bottom",
            attrs: { src: "/img/layout/add-view-dots-bottom.svg", alt: "" },
          }),
          _c("img", {
            staticClass: "page-create__decor-img is-left",
            attrs: { src: "/img/layout/add-view-dots-left.svg", alt: "" },
          }),
          _c(
            "div",
            { ref: "pageCreateHead", staticClass: "page-create__head" },
            [
              _vm._m(0),
              _c("div", { staticClass: "page-create__nav" }, [
                _c(
                  "div",
                  {
                    staticClass: "page-create__nav-item",
                    class: { "is-active": _vm.createType === "template" },
                    on: {
                      click: function ($event) {
                        _vm.createType = "template"
                      },
                    },
                  },
                  [_vm._v("\n          From our template\n        ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "page-create__nav-item",
                    class: { "is-active": _vm.createType === "link" },
                    on: {
                      click: function ($event) {
                        _vm.createType = "link"
                      },
                    },
                  },
                  [_vm._v("\n          From your Google Sheet\n        ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "page-create__nav-item",
                    on: { click: _vm.goToAiCreator },
                  },
                  [
                    _vm._v("\n          ✨ With AI Creator\n          "),
                    _c("UiIcon", {
                      staticClass: "page-create__nav-item-icon",
                      attrs: { icon: "im-external-link" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm.createType === "link"
            ? _c(
                "div",
                { staticClass: "page-create__form-wrap" },
                [
                  _c("SpreadForm", {
                    attrs: { loading: _vm.isLoading, forAddViewPage: true },
                    on: { submit: _vm.submitForm },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.createType === "template"
            ? _c(
                "div",
                { staticClass: "page-create__cards-wrap" },
                [
                  _c("div", { staticClass: "page-create__filters-wrap" }, [
                    _c("div", { staticClass: "filters" }, [
                      _c("div", { staticClass: "filters__section" }, [
                        _c(
                          "div",
                          { staticClass: "filters__item" },
                          [
                            _c("el-input", {
                              staticClass: "filters__search",
                              attrs: {
                                placeholder: "Search templates",
                                "prefix-icon": "icon-search-new",
                              },
                              model: {
                                value: _vm.filterValues.search,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterValues, "search", $$v)
                                },
                                expression: "filterValues.search",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "filters__section" },
                        [
                          _c("h5", { staticClass: "filters__section-title" }, [
                            _vm._v("Category"),
                          ]),
                          _c(
                            "el-checkbox-group",
                            {
                              staticClass: "filters__section-item is-list-view",
                              model: {
                                value: _vm.filterValues.categories,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterValues, "categories", $$v)
                                },
                                expression: "filterValues.categories",
                              },
                            },
                            _vm._l(
                              _vm.filtersOptions.categories,
                              function (item) {
                                return _c(
                                  "el-checkbox",
                                  { key: item, attrs: { label: item } },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "filters__section" },
                        [
                          _c("h5", { staticClass: "filters__section-title" }, [
                            _vm._v("Feature"),
                          ]),
                          _c(
                            "el-checkbox-group",
                            {
                              staticClass: "filters__section-item is-list-view",
                              model: {
                                value: _vm.filterValues.features,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterValues, "features", $$v)
                                },
                                expression: "filterValues.features",
                              },
                            },
                            _vm._l(
                              _vm.filtersOptions.features,
                              function (item) {
                                return _c(
                                  "el-checkbox",
                                  { key: item, attrs: { label: item } },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "filters__footer" },
                        [
                          _c("UiBtn", {
                            attrs: {
                              label: "Reset",
                              "full-width": "",
                              type: "secondary",
                            },
                            on: { click: _vm.resetFilters },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("SheetSamplesList", {
                    staticClass: "page-create__cards",
                    attrs: {
                      forAddViewPage: true,
                      sources: _vm.filteredTemplates,
                    },
                    on: {
                      "on-link-choice": (val) =>
                        _vm.checkGoogleDrivePermissions(
                          val.templateName,
                          val.from
                        ),
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Modal",
            {
              attrs: {
                modalName: "Create new website from template",
                hasCloseButton: false,
              },
              model: {
                value: _vm.useTemplateModal,
                callback: function ($$v) {
                  _vm.useTemplateModal = $$v
                },
                expression: "useTemplateModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "text--align-center pb-32 mt-20" },
                [
                  _c("p", [
                    _vm._v(
                      "We will create new website based on template data for you."
                    ),
                  ]),
                  _c("p", [
                    _vm._v("Later you can change data and styles of it."),
                  ]),
                  _c("UiBtn", {
                    staticClass: "ml-auto mr-auto mt-20",
                    attrs: { label: "Create" },
                    on: {
                      click: function ($event) {
                        return _vm.checkGoogleDrivePermissions(
                          _vm.selectedTemplateName
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "Modal",
            {
              attrs: {
                modalName: "Something went wrong :(",
                hasCloseButton: true,
              },
              model: {
                value: _vm.openErrorModal,
                callback: function ($$v) {
                  _vm.openErrorModal = $$v
                },
                expression: "openErrorModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "text--align-center pb-32 mt-20" },
                [
                  _c("p", [_vm._v("We are working on fixing this issue.")]),
                  _c("p", [_vm._v("Please try again later.")]),
                  _c("UiBtn", {
                    staticClass: "ml-auto mr-auto mt-20",
                    attrs: { label: "OK" },
                    on: {
                      click: function ($event) {
                        _vm.openErrorModal = false
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "Modal",
            {
              staticStyle: { "--modalPaddingTop": "0" },
              attrs: {
                hasCloseButton: false,
                "max-width": "700px",
                top: "auto",
              },
              on: { input: _vm.closeGoogleDrivePermissionsModal },
              model: {
                value: _vm.googleDrivePermissionsModal,
                callback: function ($$v) {
                  _vm.googleDrivePermissionsModal = $$v
                },
                expression: "googleDrivePermissionsModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "ui-flex ui-flex--items-start gap-20 p-28" },
                [
                  _c("img", {
                    staticClass: "ui-flex--none",
                    attrs: { src: "/img/lock-2.svg", alt: "lock icon" },
                  }),
                  _c("div", [
                    _c(
                      "h4",
                      { staticClass: "heading--size-s text--weight-700" },
                      [
                        _vm._v(
                          "Allow SpreadSimple to create and read the created Google Sheets\n          "
                        ),
                      ]
                    ),
                    _c("p", { staticClass: "mt-12 text--weight-500" }, [
                      _vm._v(
                        "The app will not be able to access or read any other files stored on your\n            Google Drive. Your other data will remain safe."
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _c("UiBtn", {
                        attrs: {
                          label: "Allow...",
                          uppercase: "",
                          "full-width": "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.createFromTemplate(
                              _vm.selectedTemplateName,
                              true
                            )
                          },
                        },
                      }),
                      _c("UiBtn", {
                        staticClass: "mt-12",
                        attrs: {
                          label: "Dismiss",
                          uppercase: "",
                          "full-width": "",
                          type: "secondary-text-blue",
                        },
                        on: { click: _vm.closeGoogleDrivePermissionsModal },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c("Preloader", {
        attrs: {
          loading: _vm.isLoading,
          content: _vm.contentMsg,
          showProgress: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-create__meta" }, [
      _c("h1", { staticClass: "page-create__meta-title" }, [
        _vm._v("\n          Create new Website\n        "),
      ]),
      _c("div", { staticClass: "page-create__meta-string" }, [
        _c("p", [
          _vm._v(
            "For a quick start, select one of the templates below and click the "
          ),
          _c("b", [_vm._v("Use template")]),
          _vm._v(" button."),
        ]),
        _c("p", [
          _vm._v(
            "Or if you’ve already prepared your content in Google Sheets, click the "
          ),
          _c("b", [_vm._v("From your Google Sheet")]),
          _vm._v(
            " button and paste the link to your Sheet to build a website from scratch."
          ),
        ]),
        _c("p", [
          _vm._v(
            "Looking to create something unique but not sure where to start? Click the "
          ),
          _c("b", [_vm._v("With AI Creator")]),
          _vm._v(
            " button and our AI will generate a website based on your description. "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }