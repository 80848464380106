var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      directives: [
        {
          name: "scroll-class",
          rawName: "v-scroll-class:scrolled",
          value: 70,
          expression: "70",
          arg: "scrolled",
        },
      ],
      staticClass: "manage-header",
    },
    [
      _c("div", { staticClass: "manage-header__container" }, [
        _c("div", { staticClass: "manage-header__left-side" }, [
          _c(
            "div",
            { staticClass: "manage-header__controls" },
            [
              _c("UiBtn", {
                staticClass: "manage-header__back-btn",
                attrs: {
                  to: { name: "spread-views" },
                  "icon-only": "mi-keyboard_backspace",
                  size: "m",
                  type: "ghost-secondary",
                },
              }),
              _c("div", { staticClass: "manage-header__meta" }, [
                _c(
                  "div",
                  { staticClass: "manage-header__meta-title" },
                  [
                    _c("portal-target", {
                      attrs: { name: "manage-header-title-editor", slim: "" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "manage-header__meta-badges" },
                  [
                    _c("portal-target", {
                      attrs: {
                        name: "manage-header-subscription-button",
                        slim: "",
                      },
                    }),
                    _vm.showUpgradeButton
                      ? _c(
                          "UpgradePlanButton",
                          {
                            staticClass: "manage-header__subscribe-cta",
                            attrs: { navigateToBilling: true },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.upgradeButtonLabel) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "manage-header__right-side" },
          [
            _c("TrialNotificationBar", { attrs: { small: true } }),
            _c("PhLaunchBadge"),
            _c("portal-target", {
              staticClass: "manage-header__right-side-slot",
              attrs: { name: "manage-header-menu" },
            }),
            _c("ProfileDropdown", { attrs: { userData: _vm.userData || {} } }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }