<template>
  <header v-scroll-class:scrolled=70 class="header for-manage has-border-bottom">
      <!-- @ON-MAINTENANCE: delete this afrer maintenance end -->
      <!-- <div class="header-trialing-bar is-maintenance">
        ⚠️ Due to the technical issues, you may experience problems with creating websites from templates and accessing the Sheets that were created from the templates. This doesn’t affect your websites and your data in the Sheet. We are working on fixing this issue. Please check again later.
      </div> -->
      <!-- @ON-MAINTENANCE: end -->
    <TrialNotificationBar :spreadViewId="null"/>

    <div class="header__container">
      <a href="/" class="header__logo">
        <img src="/img/ai-creator-header-logo.svg" class="is-ai-creator">
      </a>
      <div class="header__right-side">
        <PhLaunchBadge/>
        <ProfileDropdown :userData="userData || {}" />
      </div>
    </div>
  </header>
</template>
<script>
import PhLaunchBadge from '@/components/ph-launch-badge'
import ProfileDropdown from '@/components/profile-dropdown'
import TrialNotificationBar from '@/components/trial-notification-bar'
import { mapState } from 'vuex'

export default {
  name: 'LayoutHeader',
  components: {
    ProfileDropdown,
    TrialNotificationBar,
    PhLaunchBadge
  },
  computed: {
    ...mapState('app', [
      'userData'
    ])
  }
}
</script>
