var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showPhCta
    ? _c("div", { staticClass: "ph-launch-badge" }, [
        _c("span", { staticClass: "ph-live-dot" }),
        _c("a", { attrs: { href: _vm.phLink } }, [
          _vm._v("We are live on Product Hunt now! 🚀"),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }