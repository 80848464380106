<template>
  <div class="nps-popup">
    <div class="nps-popup__head">
      <div class="nps-popup__title-wrap">
        <h4 class="nps-popup__title">How likely are you to recommend us to a friend or colleague?</h4>
        <UiBtn
          iconOnly="im-close"
          size="xs"
          type="ghost-secondary"
          class="nps-popup__close-btn"
          @click="$emit('on-submit')"
        />
      </div>
      <p class="nps-popup__subtitle">This will help us to improve your experience</p>
    </div>
    <form
      @submit.prevent="submit"
      class="nps-popup__form"
    >
      <div class="nps-popup__form-item">
        <div class="nps-popup__radio-group">
          <label
            v-for="(option, i) in options"
            :key="i"
            class="nps-popup__radio"
            :class="{'is-active': selectedOption === option}"
          >
            <input
              type="radio"
              name="nps-popup"
              :value="option"
              v-model="selectedOption"
            />
            <span class="nps-popup__radio-label">{{ option }}</span>
          </label>
        </div>
        <div class="nps-popup__form-item-label-wrap">
          <span class="nps-popup__form-item-label">Not likely</span>
          <span class="nps-popup__form-item-label">Very likely</span>
        </div>
      </div>
      <UiBtn
        :disabled="!selectedOption"
        fullWidth
        label="Submit"
        attrType="submit"
        class="nps-popup__form-submit-btn"
      />
    </form>
  </div>
</template>

<script>
import UiBtn from '@/components/ui-btn/UiBtn.vue'
export default {
  name: 'NpsPopup',
  components: {
    UiBtn
  },

  data () {
    return {
      selectedOption: null,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10
      ]
    }
  },

  methods: {
    submit() {
      this.$emit('on-submit', this.selectedOption)
    }
  }
}
</script>
