var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nps-popup" }, [
    _c("div", { staticClass: "nps-popup__head" }, [
      _c(
        "div",
        { staticClass: "nps-popup__title-wrap" },
        [
          _c("h4", { staticClass: "nps-popup__title" }, [
            _vm._v(
              "How likely are you to recommend us to a friend or colleague?"
            ),
          ]),
          _c("UiBtn", {
            staticClass: "nps-popup__close-btn",
            attrs: {
              iconOnly: "im-close",
              size: "xs",
              type: "ghost-secondary",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("on-submit")
              },
            },
          }),
        ],
        1
      ),
      _c("p", { staticClass: "nps-popup__subtitle" }, [
        _vm._v("This will help us to improve your experience"),
      ]),
    ]),
    _c(
      "form",
      {
        staticClass: "nps-popup__form",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "nps-popup__form-item" }, [
          _c(
            "div",
            { staticClass: "nps-popup__radio-group" },
            _vm._l(_vm.options, function (option, i) {
              return _c(
                "label",
                {
                  key: i,
                  staticClass: "nps-popup__radio",
                  class: { "is-active": _vm.selectedOption === option },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedOption,
                        expression: "selectedOption",
                      },
                    ],
                    attrs: { type: "radio", name: "nps-popup" },
                    domProps: {
                      value: option,
                      checked: _vm._q(_vm.selectedOption, option),
                    },
                    on: {
                      change: function ($event) {
                        _vm.selectedOption = option
                      },
                    },
                  }),
                  _c("span", { staticClass: "nps-popup__radio-label" }, [
                    _vm._v(_vm._s(option)),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._m(0),
        ]),
        _c("UiBtn", {
          staticClass: "nps-popup__form-submit-btn",
          attrs: {
            disabled: !_vm.selectedOption,
            fullWidth: "",
            label: "Submit",
            attrType: "submit",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nps-popup__form-item-label-wrap" }, [
      _c("span", { staticClass: "nps-popup__form-item-label" }, [
        _vm._v("Not likely"),
      ]),
      _c("span", { staticClass: "nps-popup__form-item-label" }, [
        _vm._v("Very likely"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }