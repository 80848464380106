<template>
  <div v-cloak id="app" data-app="true" :class="currentRouteClass">
    <div v-if="isMaintenance">
      <Maintenance />
    </div>

    <LayoutBroker v-if="!isMaintenance && $auth.isLoaded()" :layouts="layouts" :current="$route.meta.layout" />
    <SubscriptionComponent />

    <!-- <OnboardingPopup
      v-if="isOnboaringWizard"
      :wizard="onboardingWizardData"
      :loading="onboardingLoading"
      @finish-onboarding="finishOnboarding"
    /> -->
    <Preloader :loading="!$auth.isLoaded()" />
  </div>
</template>
<script>
// import OnboardingPopup from '@/components/onboarding-popup/OnboardingPopup.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import LayoutBroker from 'vue-layout-broker'
import LayoutFooterless from '@/pages/_layouts/layout-footerless'
import LayoutAiCreator from '@/pages/_layouts/layout-ai-creator'
import LayoutManage from '@/pages/_layouts/layout-manage'
import LayoutEmpty from '@/pages/_layouts/layout-empty'
import LayoutHeaderless from '@/pages/_layouts/layout-headerless'
import Preloader from 'preloader-component'
import SubscriptionComponent from '@/components/subscription/SubscriptionComponent.vue'
import Maintenance from '@/pages/maintenance'
import { finishOnboardingWizard } from '@/modules/wizards/onboarding'

const layouts = {
  LayoutFooterless,
  LayoutManage,
  LayoutEmpty,
  LayoutHeaderless,
  LayoutAiCreator,
  Preloader
}
export default {
  name: 'App',
  metaInfo: {
    title: '',
    titleTemplate: '%s | SpreadSimple'
  },
  components: {
    LayoutBroker,
    SubscriptionComponent,
    Maintenance
    // OnboardingPopup
  },
  data() {
    return {
      layouts,
      // @ON-MAINTENANCE: change to true
      isMaintenance: false,
      onboardingLoading: false
    }
  },
  computed: {
    ...mapGetters('app', [
      'currentLanguage'
    ]),
    ...mapState('app', [
      'userData'
    ]),
    currentRouteClass() {
      return 'route__' + this.$route.name
    },
    isOnboaringWizard() {
      if (!this.userData) { return null }
      if (!this.userData.wizards) { return null }
      if (!this.userData.wizards.length) { return null }
      const wizard = this.userData.wizards.find(wizard => wizard.name === 'onboarding')
      if (!wizard) { return null }
      return !wizard.finished && !wizard.skipped && this.$route.name !== 'quiz'
    },
    onboardingWizardData() {
      if (!this.userData) { return null }
      if (!this.userData.wizards) { return null }
      if (!this.userData.wizards.length) { return null }
      return this.userData.wizards.find(wizard => wizard.name === 'onboarding')
    }
  },
  watch: {
    currentLanguage: {
      handler(val) {
        this.$i18n.locale = val
      }
    }
  },
  methods: {
    ...mapActions('app', [
      'setUserDataWhenLoaded'
    ]),
    async finishOnboarding() {
      try {
        this.onboardingLoading = true
        await finishOnboardingWizard()
        localStorage.removeItem('ssIsOnboardingPopupOpened')
        localStorage.removeItem('ssOnboardingWizardStatus')
      } catch (err) {
        console.warn(err)
      } finally {
        this.onboardingLoading = false
      }
    }
  },
  async created() {
    await this.setUserDataWhenLoaded()
  },

  mounted() {
    if (process.env.VUE_APP_PADDLE_ENV === 'sandbox') {
      window.Paddle.Environment.set(process.env.VUE_APP_PADDLE_ENV)
    }
    window.Paddle.Setup({
      vendor: +process.env.VUE_APP_PADDLE_VENDOR_ID
    })
  }
}
</script>
<style src='@/assets/css/main.styl' lang='stylus'></style>
