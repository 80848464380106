<template>
  <div class="spreadviews">
    <div class="spreadviews__container spreadviews__short-list-container">
      <div v-if="activeStep === 'websites-list'" class="spreadviews__right">
        <div class="spreadviews__list-head">
          <h1 class="spreadviews__title">Select a website to embed</h1>
        </div>
        <Preloader v-if="isLoading" :loading="isLoading"/>
        <div v-if="!isLoading && viewsList.length > 0" class="spreadviews__short-list">
          <div v-for="item in viewsList" :key="item.id" class="spreadview spreadviews__short-list-item" @click="onWebsiteClick(item)">
            <div>
              <img :src="item.coverImage || '/img/noPreview.png'" class="spreadviews__short-list-item-img">
            </div>
            <div class="spreadviews__short-list-item-content">
              <h3 class="spreadview__title">
                {{ item.options.spreadView.name }}
              </h3>
              <p class="spreadview__link-title">{{ websiteUrl(item) }}</p>
            </div>
            <div class="spreadviews__short-list-item-action">
              <UiBtn
                @click="onWebsiteClick(item)"
                class="manage-header__back-btn"
                icon-only="mi-keyboard_backspace"
                size="m"
                type="ghost-secondary"
              />
            </div>
          </div>
        </div>
        <div v-if="!isLoading && viewsList.length === 0" class="spreadviews__short-list">
          <div class="spreadviews__short-list-no-sites">No websites found.</div>
          <UiBtn
            label="Create Your First Website"
            to="/add"
            :targetBlank="true"
          />
        </div>
      </div>
      <div v-if="activeStep === 'show-shortcode'" class="spreadviews__right">
        <div class="spreadviews__list-head">
          <h1 class="spreadviews__title">Your short code:</h1>
        </div>
        <div  class="spreadviews__short-list">
          <div class="spreadview spreadviews__short-list-item" >
            <div class="spreadviews__short-list-item-content">
              <h3 class="spreadview__shortcode">
                {{ shortCode }}
              </h3>
            </div>
          </div>
          <UiBtn
            :label="requiredAction === 'copy' ? 'Copy' : 'Insert'"
            @click="sendShortCode"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex'
import UiBtn from '@/components/ui-btn'
import api from '@/services/api/'
export default {
  name: 'WpEmbed',
  metaInfo: {
    title: 'Websites List'
  },
  components: {
    UiBtn
  },
  data () {
    return {
      viewsList: [],
      appMode: process.env.NODE_ENV,
      isLoading: false,
      shortCode: undefined,
      activeStep: 'websites-list'
    }
  },
  computed: {
    ...mapState('app', [
      'userData',
      'currentWorkspaceId',
      'workspaces'
    ]),
    requiredAction () {
      return this.$route.query.action === 'copy' ? 'copy' : 'insert'
    }
  },
  methods: {
    ...mapActions('app', [
      'fetchUserData',
      'fetchWorkspaces'
    ]),
    async getSpreadViewsList () {
      this.isLoading = true
      this.viewsList = []
      try {
        const response = await api.getViewsList()
        this.viewsList = response.filter(i => !i.isArchived && i.options.spreadView.isPublished)
      } catch (e) {
        console.warn(e)
      } finally {
        this.isLoading = false
      }
    },
    websiteUrl (item) {
      let viewerUrl
      if (this.appMode === 'development') {
        viewerUrl = process.env.VUE_APP_VIEWER_APP_LOCAL_DOMAIN + '?_host=' + item.domain
      } else {
        viewerUrl = item.customDomain && item.customDomain.length ? 'https://' + item.customDomain : 'https://' + item.domain
      }
      return viewerUrl
    },
    async onWebsiteClick (item) {
      this.activeStep = 'show-shortcode'
      this.shortCode = `[spreadsimple_widget id="${item.id}" routing="yes" path="/"]`
    },

    copyToClipboard (text) {
      window.navigator.clipboard.writeText(text)
        .then(() => {
          this.$toasted.show('Shortcode copied to clipboard!', {
            theme: 'toasted-primary',
            position: 'top-center',
            type: 'success',
            duration: 1500
          })
        })
        .catch(err => {
          console.warn(err.message)
        })
    },
    async sendShortCode () {
      if (!this.shortCode) {
        this.activeStep = 'websites-list'
        return
      }
      const message = { action: 'sendSpreadsimpleShortCode', shortCode: this.shortCode }
      window.parent.postMessage(message, '*')
      if (this.requiredAction === 'copy') {
        this.copyToClipboard(this.shortCode)
      }
      this.activeStep = 'websites-list'
      this.shortCode = undefined
    }
  },
  async created () {
    this.isLoading = true
    if (!this.workspaces) {
      await this.fetchWorkspaces()
    }
    await this.getSpreadViewsList()
  }
}
</script>
